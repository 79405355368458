import { Box, Card, CardContent, Grid, Typography, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { motion, useAnimation } from 'framer-motion';
import React from "react";
import { useInView } from 'react-intersection-observer';
import { FiberManualRecord as BulletPointIcon } from "@mui/icons-material";

interface FeatureCardProps {
  iconSrc: string;
  title: string;
  description: string[];
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    featureSection: {
      padding: theme.spacing(3),
      backgroundImage: `url('/featurebg.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    contentBox: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      textAlign: 'center',
      color: theme.palette.common.white,
    },
    heading: {
      fontWeight: 'bold',
    },
    card: {
      flex: '1 1 300px',
      marginX: theme.spacing(2),
      height: '90%',
      '&:hover': {
        transform: 'scale(1.05)',
        transition: 'transform 0.3s ease-in-out',
      },
    },
    cardContent: {
      padding: '40px',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

const FeatureCard: React.FC<FeatureCardProps> = ({ iconSrc, title, description }) => {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);



  return (
    <Grid item xs={12} sm={6} md={3}>
      <motion.div
        className={classes.card}
        initial={{ opacity: 5, y: 100 }}
        animate={controls}
        transition={{ duration: 0.7 }}
        ref={ref}
      >
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box className={classes.cardContent}>
            <img src={`/${iconSrc}`} alt={title} width="80" height="80" />
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <List dense>
                {description.map((item, index) => (
                  <ListItem key={index} >
                        <BulletPointIcon sx={{ fontSize: 'small',  }} />
                    <ListItemText primary={item} sx={{marginLeft: '10px'}} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Card>
      </motion.div>
    </Grid>
  );
};

export const FeatureSection: React.FC = () => {
  const classes = useStyles();
  const primaryColor = useTheme().palette.primary.main;

  const featureCardsData = [
    {
      iconSrc: 'service1.png',
      title: 'Renewable Energy',
      description: ['Solar EPC', 'Wind EPC', 'Micro hydro EPC', 'Energy storage', 'Distributed energy'],
    },
    {
      iconSrc: 'service2.png',
      title: 'E-Mobility',
      description: ['EV Self-drive Rentals', 'EV intercity cab', 'Corporate travels', 'Charging station EPC', 'EV management'],
    },
    {
      iconSrc: 'service3.png',
      title: 'Agriculture',
      description: ['Agrivoltaics', 'Urban and Peri-urban agriculture', 'IoT assisted farming', 'Biofuels', 'Alternated construction materials'],
    },

    {
      iconSrc: 'service4.png',
      title: 'Consulting ',
      description: ['Carbon accounting (Scope 1-2-3)', 'Lifecycle Analysis (LCA)', 'Carbon reduction strategy', 'Global standard alignments (SDG, GRI)', 'Global sustainability disclosures (CDP, TCFD, SBTI)', 'IT based solutions'],
    },
  ];

  return (
    <Box className={classes.featureSection} id="services">
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
        <Box className={classes.contentBox}>
          <Typography variant="h4" color={primaryColor} fontWeight="bold" className={classes.heading} gutterBottom>
            Our Services
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Providing top-notch services to meet your needs.
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {featureCardsData.map((card, index) => (
            <FeatureCard key={index} {...card} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
