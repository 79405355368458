import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../context';
import { CircularProgress } from '@mui/material';

const isValidEmail = (email: string) => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  return emailRegex.test(email);
};

export const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [result, setResult] = useState({ isSubmitted: false, message: "" });

  async function handleLoginSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    setLoading(true);
  
    try {
      await login(email, password);
      console.log('Login successful!');
      // Do any additional actions for successful login
    } catch (error: any) {
      console.error('Login error:', error.message);
      console.log('Login unsuccessful!');
      setResult({
        isSubmitted: false,
        message: error.message,
      });
      // Do any additional actions for unsuccessful login
    } finally {
      setLoading(false);
    }
  }
  

  useEffect(() => {
    // Clear email field when switching back to the login form
    setEmail('');
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100vw" height="100vh" bgcolor="#f6f7f8"
      style={{
        backgroundImage: `url(/loginbg.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // filter: 'brightness(80%)',
      }}
      >
        
      <form
        onSubmit={handleLoginSubmit}
        style={{
          padding: '40px 30px',
          background: '#fefefe',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '20px',
          width: '300px',
          borderRadius: '4px',
          boxShadow: '0px 2px 6px -1px rgba(0,0,0,.12)',
        }}
      >
        <Typography variant="h4" fontWeight="600" mb={2} color="primary">
          We are <span style={{ color: '#000', fontWeight: 700 }}>Gevan</span>
        </Typography>
        <Typography variant="body1" mb={4} color="textSecondary">
          Welcome back! <br /> Log In to your account...
        </Typography>
        {result && result.message && (
          <Typography
            color="red"
            mt={2}
            fontSize={12}
          >
            {result.message}
          </Typography>
        )}
<TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setEmail(e.target.value);
            // Validate email on change
            setEmailError(!isValidEmail(e.target.value));
          }}
          error={emailError}
          helperText={emailError ? "Email is not valid." : ""}
        />        <TextField label="Password" type="password" variant="outlined" fullWidth margin="normal" InputLabelProps={{ shrink: true }} onChange={(e) => setPassword(e.target.value)} />
        <Button type="submit" variant="contained" size="large" fullWidth sx={{ mt: 2, color: '#fff', textTransform: 'none' }}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Log In'}
        </Button>
      </form>
    </Box>
  );
};
