import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { AppBar, Container, Toolbar, IconButton, Menu, MenuItem, Button, Box, Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from '../../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../context';

const tags = [
  { label: 'Home', sectionId: 'home' },
  { label: 'About', sectionId: 'about-section' },
  { label: 'Businesses', sectionId: 'businesses' },
  { label: 'Services', sectionId: 'services' },
  { label: 'Sustainability', sectionId: 'sustainability' },
  { label: 'Contact', sectionId: 'contact' },
];

const links = [
  { label: 'Home', route: '/' },
  { label: 'Businesses', route: '/businesses' },
  { label: 'Sustainability', route: '/sustainability' },
];

function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export const Navbar = () => {
  useScrollToTop();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navbarStyle: React.CSSProperties = {
    position: isScrolled ? 'fixed' : 'static',
    backgroundColor: isScrolled ? '#f7f7f7' : '#f7f7f7',
    boxShadow: 'none',
  };

  const handleTagHover = (hoveredLabel: string) => {
    tags.forEach(tag => {
      if (tag.label !== hoveredLabel) {
        const element = document.getElementById(tag.label);
        if (element instanceof HTMLElement) {
          element.style.color = '#003459';
        }
      }
    });
  };

  const handleTagHoverOut = () => {
    tags.forEach(tag => {
      const element = document.getElementById(tag.label);
      if (element instanceof HTMLElement) {
        element.style.color = '#000000';
      }
    });
  };

  const renderNavButtons = () => {
    const renderedTags = location.pathname === '/'
      ? tags.map((tag) => (
        tag.sectionId ? (
          <ScrollLink
            key={tag.label}
            to={tag.sectionId}
            spy={true}
            smooth={true}
            duration={500}
            offset={-80}
            className='some-class'
            activeClass='some-active-class'
            style={{
              cursor: 'pointer',
              color: '#000000',
              transition: 'color 0.3s ease',
              fontWeight: 'semi-bold',
            }}
            onMouseOver={() => handleTagHover(tag.label)}
                      onMouseOut={handleTagHoverOut}
                      id={tag.label}
                    >
                      {tag.label}
          </ScrollLink>
        ) : null
      ))
      : links.map((link) => (
        <RouterLink
          key={link.label}
          to={link.route}
          style={{
            cursor: 'pointer',
            color: '#003459',
            transition: 'color 0.3s ease',
            textDecoration: 'none',
          }}
          id={link.route}
        >
          {link.label}
        </RouterLink>
      ));

    if (isLoggedIn) {
      renderedTags.push(
        <Button variant="contained" key="logout"  sx={{ fontSize: 14, textTransform: 'none', fontColor: '#003459' }}  onClick={logout}>
          Logout
        </Button>
      );
    }

    return <>{renderedTags}</>;
  };

  return (
    <AppBar style={navbarStyle}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', mt:2 }}>
              <img src={logoImage} alt="Logo" onClick={()=>navigate('./')} style={{ width: '70px', height: 'auto' }} />
              {/* <Typography fontSize={28} fontWeight={"bold"} color='secondary' onClick={()=>navigate('./')} style={{ cursor: 'pointer' }}>
              Geवन
              </Typography>*/}
            </Box> 

            {isMobile ? (
              <IconButton size="large" aria-label="menu" onClick={handleOpenNavMenu}  sx={{ ml: 'auto', color:"#003459" }}>
                <MenuIcon sx={{ fontSize: 40 }} />
              </IconButton>
            ) : (
              <Stack direction="row" spacing={5} sx={{ alignItems: 'center' }}>
                {renderNavButtons()}
              </Stack>
            )}
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: isMobile ? 'block' : 'none' }}
            elevation={3} // Adjust the elevation level
  PaperProps={{
    // Customize the Paper component within the Menu
    sx: {
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', color:'red' // Add a shadow
    },
  }}
          >
            {location.pathname === '/'
              ? tags.map((tag) => (
                tag.sectionId ? (
                  <MenuItem key={tag.label}>
                    <ScrollLink
                      to={tag.sectionId}
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-80}
                      onClick={handleCloseNavMenu}
                      className='some-class'
                      activeClass='some-active-class'
                      style={{
                        cursor: 'pointer',
                        color: '#1a2a36',
                        transition: 'color 0.3s ease',
                      }}
                    >
                      {tag.label}
                    </ScrollLink>
                  </MenuItem>
                ) : null
              ))
              : links.map((link) => (
                <MenuItem key={link.label} onClick={() => {
                  handleCloseNavMenu();
                  navigate(link.route);
                }}>
                  <Typography
                    textAlign="center"
                    style={{
                      cursor: 'pointer',
                      color: location.pathname === link.route ? '#808080' : '#1a2a36',
                      transition: 'color 0.3s ease',
                    }}
                  >
                    {link.label}
                  </Typography>
                </MenuItem>
              ))}
            {isLoggedIn && (
              <MenuItem onClick={handleCloseNavMenu}>
                <Button  variant="contained" key="logout" color="primary" sx={{ fontSize: 14, textTransform: 'none' }} onClick={logout}>
                  Logout
                </Button>
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

