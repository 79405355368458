import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export const CarbonNeutral: React.FC = () => {
  const textOptions = [
    'Commitment',
    'Initiatives Being Taken',
    'Envisaged Results',

  ];

  const descriptionOptions = [
    "Our journey towards sustainability involves conscious decisions and continuous improvements. We are dedicated to being carbon neutral and actively contributing to a sustainable future. Our unwavering commitment extends beyond words - it's a pledge to minimize our environmental impact. Join us on this journey as we strive for a world where every action contributes to a healthier planet.",
    "We have multiple projects in progress aimed at creating a greener and cleaner world. . These initiatives bring new perspectives in renewable energy application while reducing carbon and increase economic returns. They range from off-grid EV charging stations, mobile EV charging station, urban agrivoltaics and natural construction material. Contact us for fostering environmental consciousness through initiatives that empower communities, preserve biodiversity, and pave the way for a sustainable, harmonious future.",
    "By gradually taking small steps in the path to a greener tomorrow, we aim to offset all, if any, carbon emissions emitted in our operations. Our solutions are designed to set benchmarks in renewable energy applications for future generations and support governments in reaching their carbon reduction goals while providing everlasting benefits to the citizens of Earth. Unite with us in this journey."
  ];

  const colorOptions = ['#6CE5B1', '#FE9968', '#7FE0EB', '#EBB9D2'];

  const imageOptions = [
    require('../../images/carbon1.jpg'),
    require('../../images/carbon2.jpg'),
    require('../../images/carbon3.png'),
    require('../../images/carbon4.jpg'),
  ];

  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % textOptions.length);
  };

  const handlePrevious = () => {
    setIndex((prevIndex) => (prevIndex - 1 + textOptions.length) % textOptions.length);
  };

  return (
    <Container style={{ marginTop: '10px' }}>
      <Card style={{ backgroundColor: colorOptions[index] }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="h4" fontSize="18px" fontWeight="700" color='#555555'>
                  {textOptions[index]}
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }} color='#555555'>
                  {descriptionOptions[index]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src={imageOptions[index]}
                alt={`Slide ${index + 1}`}
                style={{ width: '100%', height: 'auto', maxHeight: '240px', objectFit: 'cover' }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box sx={{display:'flex', justifyContent:'center'}}>
          <IconButton onClick={handlePrevious} color="secondary" aria-label="previous">
            <ArrowBack />
          </IconButton>
          <IconButton onClick={handleNext} color="secondary" aria-label="next">
            <ArrowForward />
          </IconButton>
        </Box>
        
      </Card>
      <Typography sx={{ color: '#555555', lineHeight: 1.6, fontSize: 16, marginTop: '16px', textAlign: 'center' }}>
        We recognize the urgency of the climate crisis and believe every business has a responsibility to act. That's why Gevan is committed to achieving carbon neutrality. We're not just reducing our own footprint, but also collaborating with partners and inspiring others to join the movement. Follow our progress, discover how you can make a difference, and together, let's pave the way for a zero-carbon future.
      </Typography>
    </Container>
  );
};
