import React from 'react';
import { Tabs, Tab, Box, Typography, useTheme } from '@mui/material';
import { SDGAlignment } from './sdgs';
import { CarbonNeutral } from './cabonNeutral';

const carbonNeutralImages = ['carbon2.jpg', 'carbon1.jpg',];

interface TabPanelProps {
    value: number;
    index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, index, }) => {
    return (
        <div role="tabpanel" hidden={value !== index} style={{ backgroundColor: '#f6f7f8' }} >
            {value === index && (
                <Box p={3}>
                    {index === 0 ? ( <SDGAlignment />) : ( <CarbonNeutral /> )}
                </Box>
            )}
        </div>
    );
};

export const Section3: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => { setValue(newValue);};
    return (
        <Box width="90%" margin="auto">
            <Tabs
                value={value}
                onChange={handleChange}
                centered={true}
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{ "& .MuiTabs-flexContainer": { justifyContent: { xs: 'normal', sm: "center" } },}}
            >
                <Tab label="SDG alignment" />
                <Tab label="Carbon Neutral" />
            </Tabs>

            <TabPanel value={value} index={0} />
            <TabPanel value={value} index={1} />
        </Box>
    );
};


