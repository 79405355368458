import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Use Link from 'react-router-dom' for navigation
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      background: theme.palette.primary.main,
    },
    content: {
      textAlign: 'center',
      color: theme.palette.common.white,
    },
    title: {
      fontSize: '5rem',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
    },
    text: {
      fontSize: '1.5rem',
      marginBottom: theme.spacing(4),
    },
    button: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      padding: theme.spacing(1.5, 4),
      borderRadius: theme.shape.borderRadius,
      textDecoration: 'none',
      transition: 'background-color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  })
);

export const Page404: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Box className={classes.content}>
          <Typography variant="h1" className={classes.title}>
            404
          </Typography>
          <Typography variant="body1" className={classes.text}>
            Oops! The page you are looking for might be in another galaxy.
          </Typography>
          <Button component={Link} to="/" variant="contained" className={classes.button}>
            Go Home
          </Button>
        </Box>
      </Container>
    </div>
  );
};


