import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';

const useStyles = makeStyles((theme) => ({
  b1: {    fontSize: '1.4em',
    width: '80%',
    margin: '50px auto',
    color: '#555555',
    padding: '1.2em 30px 1.2em 75px',
    borderLeft: '8px solid #003459',
    lineHeight: 1.2,
    background: '#fff',
  },
  b2: {
    display: 'block',
    color: '#003459',
    fontWeight: 'bold',
    lineHeight: 1.6,
  },
  main: {
    backgroundColor: '#f6f7f8',
  },
}));

export const Section2: React.FC = () => {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  const animateText = async () => {
    await controls.start({ opacity: 1, y: 0, transition: { duration: 1, ease: 'easeInOut' } });
  };

  // Trigger the animation when the component is in view
  useEffect(() => {
    if (inView) {
      animateText();
    }
  }, [inView]);

  return (
    <motion.div className={classes.main} initial={{ opacity: 0, y: 20 }} animate={controls} ref={ref}>
      <br />
      <motion.div className={classes.b1}>
       <span className={classes.b2}>Gevan</span>
        <span style={{ fontSize: 16 }}>
        Embrace the future with us! This page unveils our dedication to a sustainable world, showcasing alignment with the SDGs and our journey to carbon neutrality. Explore our initiatives, understand our approach, and join us in weaving a brighter future, together.
        </span>
      </motion.div>
      <br />
    </motion.div>
  );
};
