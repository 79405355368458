import React, { ReactNode } from 'react';
import { Grid, Card, CardContent, Typography, Container, CardActionArea, CardMedia, CardActions, Button, useTheme, Box } from "@mui/material";
import { Element } from 'react-scroll';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { CustomButton } from '../button';
import { useNavigate } from 'react-router-dom';

interface BusinessItem {
  id: number;
  title: string;
  description: string;
  image: string;
}

interface AnimatedSectionProps {
  delay: number;
  children: ReactNode;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ delay, children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  const variants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{ duration: 1, delay }}
    >
      {children}
    </motion.div>
  );
};

export const Business: React.FC = () => {
  const primaryColor = useTheme().palette.primary.main;

  const data: BusinessItem[] = [
    {
      "id": 1,
      "title": "Renewable Energy",
      "description": "Discover innovative solutions in the field of renewable energy, paving the way for a sustainable future. Explore groundbreaking technologies and practices that harness green energy sources to meet our growing power needs.",
      "image": "/b1.jpg"
    },
    {
      "id": 2,
      "title": "E-Mobility",
      "description": "Embark on a journey into the world of E-Mobility, where cutting-edge technologies redefine transportation. Dive into the latest developments in electric vehicles, charging infrastructure, and sustainable transportation options, contributing to a cleaner and greener planet.",
      "image": "/b2.jpg"
    },
    {
      "id": 3,
      "title": "Agriculture",
      "description": "Experience the essence of modern agriculture, where innovation meets tradition. Explore advancements in farming practices, agrotech solutions, and sustainable agriculture methods that ensure efficient, eco-friendly, and resilient food production to meet the demands of a growing global population.",
      "image": "/b3.jpg"
    },
    {
      "id": 4,
      "title": "Consulting",
      "description": "Initiate your transformation with us towards the global sustainability agenda and align your business to global requirements of carbon reduction. Our team of passionate consultants are well trained and determined to understand business processes, to further identify sustainability gaps and provide relevant solutions.",
      "image": "/b4.jpg"
    }
  ];

  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Navigate to the "/businesses" route
    navigate('/businesses');
  };

  return (
    <Element name="businesses">
      <div style={{ backgroundColor: primaryColor, paddingTop: '0px' }}>
        <Container sx={{ padding: '6%' }} disableGutters maxWidth="xl">
          <AnimatedSection delay={0.0}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant="h4" fontWeight="bold" color={useTheme().palette.secondary.main} >
            Businesses  
          </Typography>
          <CustomButton onClick={handleButtonClick} />
          </Box>
          </AnimatedSection>
          <AnimatedSection delay={0.2}>
            <Typography variant="h6" fontSize="18px" paragraph color={useTheme().palette.secondary.main}>
            Explore our businesses that are driving sustainable transformation through innovation and application in diverse sectors
            </Typography>
          </AnimatedSection>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            {data.map((result: BusinessItem, index: number) => (
              <Grid item xs={12} sm={4} md={6} key={index}>
                <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={result.image}
          alt={result.title}
          style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
        />
        <CardContent style={{ flex: 1, overflow: 'hidden' }}>
          <AnimatedSection delay={0.4}>
            <Typography gutterBottom variant="h5" component="div">
              {result.title}
            </Typography>
          </AnimatedSection>
          <AnimatedSection delay={0.6}>
            <Typography variant="body2" color="text.secondary" fontSize="16px">
              {result.description}
            </Typography>
          </AnimatedSection>
        </CardContent>
      </CardActionArea>
    </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </Element>
  );
};


