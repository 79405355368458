import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box, Card, CardContent, CardMedia, Container, Grid, Button, Grow, Slide } from '@mui/material';
import { Element } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

// Sample SDG data
const sdgData = [
  {
    id: 1,
    title: 'Goal 1',
    description: 'Ensure access to affordable, reliable, sustainable and modern energy for all',
    color: '#FCC30B', 
  },
  {
    id: 2,
    title: 'Goal 2',
    description: 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
    color: '#FD6925', 
  },
  {
    id: 3,
    title: 'Goal 3',
    description: 'Make cities and human settlements inclusive, safe, resilient and sustainable ',
    color: '#FD9D24', 
  },
  {
    id: 4,
    title: 'Goal 4',
    description: 'Take urgent action to combat climate change and its impacts ',
    color: '#3F7E44', 
  },
  {
    id: 5,
    title: 'Goal 5',
    description: 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
    color: '#56C02B', 
  },
  {
    id: 6,
    title: 'Goal 6',
    description: 'Ensure availability and sustainable management of water and sanitation for all',
    color: '#00689D', 
  },
];

export const Sustainability: React.FC = () => {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const primaryColor = useTheme().palette.primary.main;
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/sustainability');
  };

  return (
    <Element name="sustainability">
      <Box sx={{ backgroundColor: primaryColor }}>
        <Container sx={{ padding: '6%' }} disableGutters maxWidth="xl">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4" fontWeight="bold" color={useTheme().palette.secondary.main}>
              Sustainability
            </Typography>
          </Box>
          <Typography variant="h6" fontSize="18px" color={useTheme().palette.secondary.main} paragraph>
            Building a Better Tomorrow, Today
          </Typography>
          <Typography color={useTheme().palette.secondary.main} sx={{fontSize:'17px'}} paragraph>
          Sustainability doesn't mean sacrifice; it means building a future where vibrant economies thrive alongside healthy ecosystems and thriving communities. It's about leaving a legacy not of depletion but of innovation and collaboration. Our alignment with United Nations Sustainable Development Goals is a testament to our beliefs in a healthy Earth.
          </Typography>

          {/* Grid for displaying 6 SDG cards */}
          <Grid container spacing={2} mb={3}>
            {sdgData.map((sdg) => (
              <Grid item xs={12} sm={6} md={4} key={sdg.id}>
                <Card
                  sx={{
                    position: 'relative',
                    height: '350px',
                    overflow: 'hidden',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onMouseEnter={() => setHoveredCard(sdg.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  {/* Background cover image */}
                  <CardMedia
    component="div" // Use a div wrapper to apply custom styles
    style={{
        backgroundImage: `url(./backsdg${sdg.id}.jpg)`,
        backgroundSize: 'cover',
        height: '100%',
        filter: 'brightness(40%)', // Adjust the brightness as needed
    }}
/>

                  {/* Small image on bottom left corner with padding */}
                  <img
                    src={`./sdg${sdg.id}.png`}
                    alt="Small Image"
                    style={{
                      position: 'absolute',
                      bottom: 16,
                      left: 16,
                      width: 150,
                      height: 150,
                      borderRadius: '8px',
                    }}
                  />

                  {/* Nested card with colored background that covers the main card on hover */}
                  <Slide direction="up" in={hoveredCard === sdg.id} timeout={500} mountOnEnter unmountOnExit>
                    <Card
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: sdg.color,
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CardContent>
                        {/* Content of the inner card */}
                        <Box style={{ color: 'white', textAlign: 'center' }}>
                          <Typography variant="h6" style={{ fontWeight: 'bold' }}>{sdg.title}</Typography>
                          <Typography variant="body1">{sdg.description}</Typography>
                          <Button onClick={handleButtonClick} variant="outlined" style={{ color: 'white', borderColor: 'white', marginTop: '8px' }}>More Info</Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Slide>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Element>
  );
};
