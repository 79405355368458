import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, TextField } from '@mui/material';
import { getFirestore, collection, getDocs, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { useAuth } from '../../context';

type FormData = {
  id: string;
  name: string;
  email: string;
  subject: string;
  message: string;
  checked: boolean;
  createdDate: Timestamp;
}[];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 400,
      margin: 'auto',
      marginTop: theme.spacing(4),
      backgroundColor: '#f8f8f8',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      borderRadius: 10,
      overflow: 'hidden',
      position: 'relative',
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    title: {
      color: '#333',
      marginBottom: theme.spacing(2),
    },
    text: {
      color: '#666',
      marginBottom: theme.spacing(1),
    },
    deleteIcon: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  })
);

export const ContactForms = () => {
  const classes = useStyles();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(null);
  const [formData, setFormData] = useState<FormData>([]);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [result, setResult] = useState({ isSubmitted: false, message: "" });
  const { changePassword } = useAuth();


  const fetchDataFromFirestore = async () => {
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, 'contacts'));
    const data = querySnapshot.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    })).sort((a, b) => b.createdDate?.toDate() - a.createdDate?.toDate()) as FormData;
    setFormData(data);
  };

  useEffect(() => {
    fetchDataFromFirestore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = async (index: number, isChecked: boolean) => {
    const selectedDocId = formData[index].id;
    await updateDoc(doc(getFirestore(), 'contacts', selectedDocId), { checked: isChecked });
    const updatedFormData = [...formData];
    updatedFormData[index].checked = isChecked;
    setFormData(updatedFormData);
  };

  const handleDeleteClick = (index: number) => {
    setSelectedCardIndex(index);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedCardIndex !== null) {
      await deleteDoc(doc(getFirestore(), 'contacts', formData[selectedCardIndex].id));
      const updatedFormData = [...formData];
      updatedFormData.splice(selectedCardIndex, 1);
      setFormData(updatedFormData);
    }

    setOpenDeleteDialog(false);
  };

  const handleDeleteCancel = () => {
    setSelectedCardIndex(null);
    setOpenDeleteDialog(false);
  };


  const handlePasswordChangeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await changePassword(newPassword);
      setShowChangePasswordForm(false);
    } catch (error: any) {
      console.error('Change Password Error:', error.message);
      setResult({
        isSubmitted: false,
        message: error.message,
      });
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        <Typography variant="h4" gutterBottom sx={{ pt: 3, paddingLeft: 4, color: 'primary.main' }}>
          Contact Forms
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setShowChangePasswordForm(true)} sx={{ mr: 4, color: 'white', textTransform: 'none', whiteSpace: 'nowrap' }}>
          Change Password
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ display: 'flex', p: 4, pt: 0 }}>
        {formData.map((data, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.card}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <Checkbox
                  color="primary"
                  checked={data.checked}
                  onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                />

                <IconButton
                  className={classes.deleteIcon}
                  onClick={() => handleDeleteClick(index)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <CardContent className={classes.cardContent}>
                <Typography variant="h5" component="div" className={classes.title}>
                  Name: {data.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={classes.text}>
                  Email: {data.email}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={classes.text}>
                  Subject: {data.subject}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={classes.text}>
                  Date: {data.createdDate ? format((data.createdDate as Timestamp).toDate(), 'MMMM dd, yyyy') : 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={classes.text}>
                  Message: {data.message}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this entry?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showChangePasswordForm} onClose={() => setShowChangePasswordForm(false)}>
        <IconButton
          aria-label="close"
          onClick={() => setShowChangePasswordForm(false)}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <form
          onSubmit={handlePasswordChangeSubmit}
          style={{
            padding: '40px 30px',
            background: '#fefefe',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: '20px',
            width: '300px',
            borderRadius: '4px',
            boxShadow: '0px 2px 6px -1px rgba(0,0,0,.12)',
          }}
        >
          <Typography variant="h4" fontWeight="600" mb={2} color="primary">
            Change Password
          </Typography>
          {result && result.message && (
            <Typography
              color="red"
              mt={2}
              fontSize={12}
            >
              {result.message}
            </Typography>
          )}
          <TextField
            label="Current Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button type="submit" variant="contained" size="large" fullWidth sx={{ mt: 2, color: '#fff', textTransform: 'none' }}>
            Change Password
          </Button>
        </form>
      </Dialog>
    </Box>
  );
};
