import React from 'react';
import { Button, IconButton, TextField, Box } from '@mui/material';
import { Facebook, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

export const Footer = () => {
  const companyLinks = [
    { label: 'Home', link: '/' },
    { label: 'Businesses', link: '/businesses' },
    { label: 'Sustainability', link: '/sustainability' },
  ];

  const contactInfo = [
    { icon: 'location_on', text: '1-B DDA New Friends colony', },
    { icon: 'pin', text: 'Delhi-110065' },
    { icon: 'phone', text: '+91-8126508612' },
    { icon: 'email', text: 'connect@gevan.in'}
  ];

  const socialLinks = [
    { icon: <Twitter fontSize="large" />, scale: 1.3 },
    { icon: <Facebook fontSize="large" />, scale: 1.3 },
    { icon: <YouTube fontSize="large" />, size: 40, scale: 1.3 },
    { icon: <LinkedIn fontSize="large" />, scale: 1.3 },
  ];
  

  return (
    <Box
      component="div"
      bgcolor="primary.main"
      color="secondary.main"
      paddingTop="5%"
      padding="20px"
      marginTop="-8px"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box flex="1" marginBottom={{ xs: '20px', md: 0 }}>
          <h4 style={{ fontSize: '1.5rem', fontWeight: 'normal', marginBottom: '1rem', color: 'secondary.main' }}>
            Company
          </h4>
          {companyLinks.map((link, index) => (
            <Button key={index} style={{ color: '#fff', marginBottom: '5px', display: 'flex', justifyContent: 'flex-start' }} href={link.link}>
              {link.label}
            </Button>
          ))}
        </Box>
        <Box flex="1" marginBottom={{ xs: '20px', md: 0 }}>
          <h4 style={{ fontSize: '1.5rem', fontWeight: 'normal', marginBottom: '1rem', color: 'secondary.main' }}>
            Contact
          </h4>
          {contactInfo.map((info, index) => (
            <p key={index} style={{ marginBottom: '5px', }}>
              {React.createElement(info.icon,)}
              {info.text}
            </p>
          ))}
          
        </Box>
        <Box flex="1" marginBottom={{ xs: '20px', md: 0 }}>
          <h4 style={{ fontSize: '1.5rem', fontWeight: 'normal', marginBottom: '1rem', color: 'secondary.main' }}>
            About the company
          </h4>
          <p style={{ marginBottom: '1rem' }}>At Geवन, we aim to develop renewable energy jungles that support the ongoing transition and rural development. To help individuals and businesses to transition to emission free age, we deploy futuristic design philosophies aligned to users’ requirements.</p>
        </Box>
        
        <Box flex="1" marginBottom={{ xs: '20px', md: 0 }} display="flex" flexDirection="column" alignItems='center' >
          <h4 style={{ fontSize: '1.5rem', fontWeight: 'normal', marginBottom: '1rem', color: 'secondary.main' }}>
            Follow Us
          </h4>
          <Box display="flex" paddingTop="10px" gap="10px">
            {socialLinks.map((social, index) => (
              <IconButton key={index} style={{ color: '#f1f8ff', marginRight: '5px', width: '35px', height: '35px', borderRadius: '50%', }}>
                {social.icon}
              </IconButton>
            ))}
          </Box>
        </Box>
      </Box>
      <Box borderTop="1px solid rgba(255 , 255, 255)" padding="15px 0" fontSize="15px" textAlign="center" margin="auto">
        <Box flex="1" textAlign="center">
          {`© ${new Date().getFullYear()} `}
          <a  style={{ color: '#f1f8ff', marginRight: '10px', }}>
            Gevan,
          </a>
           All Rights Reserved
        </Box>
      </Box>
    </Box>
  );
};

