import React from 'react';
import { Grid, Paper, Typography, Box, IconButton } from '@mui/material';
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { Twitter, Facebook, Instagram } from '@mui/icons-material';
import { ThemeContext } from '@emotion/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    introContainer: {
      padding: theme.spacing(8),
      backgroundImage: `url('/featurebg.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      // [theme.breakpoints.down('sm')]: {
      //   height: 'auto',
      // },
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageContainer: {
      padding: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: 'auto',
      maxWidth: 400, 
    },
    socialIconsContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'center',
    },
    socialIcon: {
      color: useTheme().palette.primary.main,
      fontSize: 50,
    },
  })
);

export const Intro: React.FC = () => {
  const classes = useStyles();
  const primaryColor = useTheme().palette.primary.main;

  return (
    <Grid container className={classes.introContainer} spacing={4}>
      {/* Left side - Text content */}
      <Grid item xs={12} md={6} className={classes.paper}>
        <Paper sx={{padding: '50px', backgroundColor: primaryColor}}>     
          <Typography variant="h4" color="white" gutterBottom>
          Chairman's Message
          </Typography>
          <Typography variant="body1" color="white" fontSize="18px" paragraph>
            Gevan embodies a structure whose soul is powered by nature, for the societies that come tomorrow, to build a happy Earth. May we align our objectives to create sustainable systems to move forward to a low pollution world.
          </Typography>
          <Typography variant="subtitle1" color="white">
            “Charging today for clean tomorrow” 
          </Typography>
          </Paper>      
      </Grid>

      {/* Right side - Image */}
      <Grid item xs={12} md={6} className={classes.imageContainer}>
        <Box>
          <img src="/intro.png" alt="Intro Image" className={classes.image} />
          <div className={classes.socialIconsContainer}>
            <IconButton>
              <Twitter className={classes.socialIcon} />
            </IconButton>
            <IconButton>
              <Facebook className={classes.socialIcon} />
            </IconButton>
            <IconButton>
              <Instagram className={classes.socialIcon} />
            </IconButton>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
