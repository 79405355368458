import React, { useState } from 'react';
import { Grid, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { db, collection, addDoc } from '../../firebase.config';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contactUsContainer: {
            backgroundColor: '#f6f7f8',
            flexDirection: 'column-reverse',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
            },
        },
        contactContent: {

            padding: theme.spacing(6),
        },
        contactHeading: {
            mb: 2,
            fontFamily: 'Roboto',
        },
        contactSubHeading: {
            color: '#1a2a36',
            fontFamily: 'Roboto',
        },
        contactText: {
            color: '#9b9b9b',
            fontFamily: 'Open Sans',
            fontWeight: '700'
        },
        formContainer: {
            maxWidth: 400,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
        },
        formTextField: {
            '& .MuiInputBase-input': {
                backgroundColor: 'white',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'primary.main', // Set the border color to transparent when focused
            },
            '& .MuiInputBase-multiline': {
                backgroundColor: 'white',
            }
        },
        fadeOutMessage: {
            opacity: 1,
            transition: 'opacity 2s ease-out', // Adjust the duration as needed
            marginTop: '10px',
            color: 'green',
        },
        fadedOut: {
            opacity: 0,
        },

    })
);

const isValidEmail = (email: string) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return emailRegex.test(email);
};

export const ContactUs: React.FC<{}> = () => {
    const classes = useStyles();
    const primaryColor = useTheme().palette.primary.main;
    const [formData, setFormData] = React.useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        checked: false,
        createdDate: new Date(),
    });

    const [touchedEmail, setTouchedEmail] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const isFormValid = formData.name && formData.email && formData.subject && formData.message;
    const [result, setResult] = useState({ isSubmitted: false, message: "" });

    const handleFormSubmit = async () => {
        try {
            setLoading(true);
            // Add the form data to Firestore
            const docRef = await addDoc(collection(db, 'contacts'), formData);

            // Clear the form fields after submission
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: '',
                checked: false,
                createdDate: new Date(),
            });
            setResult({
                isSubmitted: true,
                message: 'We have received your request. We\'ll get back to you shortly.'
            });
            setSubmitted(true);
            setSuccessMessage('Form submitted successfully!');
            setTimeout(() => {
                setSuccessMessage(classes.fadedOut);
            }, 5000);


        } catch (error) {
            console.error('Error submitting form data:', error);
            setResult({
                isSubmitted: false,
                message: 'We are facing some technical issue. Please try again after sometime.'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container className={classes.contactUsContainer} id="contact">
            {/* Left side - Image */}
            <Grid item xs={12} md={6} sx={{ objectFit: 'cover', }}>
                <img src="/contact.jpg" alt="Contact Us" style={{ width: '100%', height: '100%', }} />
            </Grid>

            {/* Right side - Content */}
            <Grid item xs={12} md={6} className={classes.contactContent}>
                <Typography variant="h5" fontWeight="bold" color={primaryColor} className={classes.contactHeading} >
                    Contact Us
                </Typography>
                <Typography variant="h4" className={classes.contactSubHeading} sx={{ mb: 2 }}>
                Find Information & Explore Synergies
                </Typography>
                <Typography variant="body1" className={classes.contactText} sx={{ mb: 2, fontWeight: '700' }}>
                Join us in shaping a sustainable future! As a growing company dedicated to our vision, we're committed to driving positive change. Contact us today to explore how we can collaborate towards creating a cleaner, greener Earth.

                </Typography>

                {/* Contact Form */}
                <Box className={classes.formContainer}>
                    {successMessage && result.message && (
                        <div className={`${classes.fadeOutMessage} ${successMessage}`}>
                            {result && result.message && (
                                <Typography
                                    color={result.isSubmitted ? "primary" : "red"}
                                    mt={2}
                                    fontSize={12}
                                >
                                    {result.message}
                                </Typography>
                            )}
                        </div>
                    )}
                    <TextField
                        label="Your Name*"
                        variant="outlined"
                        fullWidth
                        className={classes.formTextField}
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                    label="Your Email*"
                    variant="outlined"
                    fullWidth
                    className={classes.formTextField}
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={() => setTouchedEmail(true)} // Set touchedEmail to true on blur
                    error={!submitted && touchedEmail && !isValidEmail(formData.email)}
                    helperText={!submitted && touchedEmail && !isValidEmail(formData.email) ? "Email is not valid." : ""}
                />
                    <TextField
                        label="Subject*"
                        variant="outlined"
                        fullWidth
                        className={classes.formTextField}
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Message*"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        className={classes.formTextField}
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        inputProps={{
                            style: {
                                padding: 0
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: primaryColor, color: '#fff', textTransform: 'none' }}
                        fullWidth
                        onClick={handleFormSubmit}
                        disabled={!isFormValid}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                    </Button>
                </Box>
            </Grid>

        </Grid>
    );
};

