import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Grid, useTheme } from '@mui/material';

interface SDGSProps {
  sdgImage: string;
  sdgAlt: string;
  title: string;
  content: string;
}

const SDGS: React.FC<SDGSProps> = ({ sdgImage, sdgAlt, title, content }) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card sx={{ height: '95%', marginBottom: '2em', backgroundColor: theme.palette.secondary.main }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="280"
            image={sdgImage}
            alt={sdgAlt}
            style={{ objectFit: 'fill' }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export const SDGAlignment = () => (
  <div style={{ margin: '0 auto', padding: '3em 1em' }}>
    <header style={{ textAlign: 'center', fontSize: '1.2em' }}>
      <Typography variant="h1" color="primary" style={{ fontWeight: 500, lineHeight: 1.2, fontSize: '2em' }} >
        Driving Positive Change: Our Multi-Faceted Commitment to Global Sustainability Goals
      </Typography>
      <Typography >Transforming Lives and Ecosystems through Aligned Actions</Typography>
    </header> 
    <main style={{ marginTop: '3em' }}>
      <Grid container spacing={3}>
        {[
          {
            sdgImage: './sdg1.png',
            sdgAlt: 'SDG Image 7',
            title: 'SDG 7: Affordable and Clean Energy',
            content: 'At Gevan, we are deeply committed to SDG 7: Affordable and Clean Energy. Our initiatives focus on developing and implementing sustainable energy solutions. By investing in renewable energy sources, promoting energy efficiency, and adopting eco-friendly practices, we contribute to the global effort to ensure access to affordable and clean energy for all.',
          },
          {
            sdgImage: './sdg2.png',
            sdgAlt: 'SDG Image 9',
            title: 'SDG 9: Industry, Innovation, and Infrastructure',
            content: 'Embracing the principles of SDG 9, Gevan is dedicated to fostering innovation and building resilient infrastructure. We invest in technological advancements, support research and development, and contribute to the growth of sustainable industries. Our commitment to SDG 9 reflects our belief in the power of innovation to drive positive change and enhance infrastructure for long-term economic development.',
          },
          {
            sdgImage: './sdg3.png',
            sdgAlt: 'SDG Image 11',
            title: 'SDG 11: Sustainable Cities and Communities',
            content: 'As part of our corporate responsibility, Gevan actively supports SDG 11: Sustainable Cities and Communities. We contribute to the development of inclusive, safe, resilient, and sustainable cities. Through urban planning, community engagement, and environmentally conscious practices, we aim to create thriving urban spaces that prioritize the well-being of residents and the environment.',
          },
          {
            sdgImage: './sdg4.png',
            sdgAlt: 'SDG Image 13',
            title: 'SDG 13: Climate Action',
            content: 'Gevan is dedicated to SDG 13: Climate Action. Recognizing the urgency of addressing climate change, we implement measures to reduce our carbon footprint, promote sustainable practices, and adapt to a changing climate. By integrating climate-conscious strategies into our operations, we strive to contribute to global efforts to combat climate change and build a more sustainable future.',
          },
          {
            sdgImage: './sdg5.png',
            sdgAlt: 'SDG Image 15',
            title: 'SDG 15: Life on Land',
            content: 'Committed to preserving biodiversity and supporting SDG 15: Life on Land, Gevan takes active steps to protect and restore ecosystems. Our initiatives focus on responsible land use, reforestation efforts, and conservation projects. We believe in the importance of safeguarding terrestrial ecosystems to ensure a balanced and sustainable coexistence with the natural world.',
          },
        ].map((sdg, index) => (
          <SDGS
            key={index}
            sdgImage={sdg.sdgImage}
            sdgAlt={sdg.sdgAlt}
            title={sdg.title}
            content={sdg.content}
          />
        ))}
      </Grid>
    </main>
  </div>
);