// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore'; // Add these Firestore imports

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQbR6aWkqruWayk7Hm_MVfQeLhhhf4ixE",
  authDomain: "gevan-tech.firebaseapp.com",
  projectId: "gevan-tech",
  storageBucket: "gevan-tech.appspot.com",
  messagingSenderId: "668621573884",
  appId: "1:668621573884:web:ea40fae29b8ac872a9d179"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Change this line to getFirestore

export { auth, signInWithEmailAndPassword, updatePassword, db, collection, addDoc };
