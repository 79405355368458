import React from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';

// Sample image URLs
const renewableEnergyImages = [
    '/s1.jpg',
    '/renewable1.jpg',
    '/renewable2.webp',
    '/renewable3.webp',
];

const eMobilityImages = [
    'electric2.webp',
    'electric1.jpg',
    'electric4.webp',
    'electric3.webp',
];

const agricultureImages = [
    'agriculture1.jpg',
    'agriculture2.jpg',
    'agriculture3.jpg',
    'agriculture4.jpg',
];

const consultingImages = [
    'consulting1.jpg',
    'consulting2.jpg',
    'consulting3.jpg',
    'consulting4.jpg',
];

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index}>
        {value === index && (
            <Box p={3}>
                {/* Use type assertion to explicitly specify the type of children */}
                {children && (
                    <>
                        <ImageGallery images={(children as [string[], string])[0]} />
                        <Typography>{(children as [string[], string])[1]}</Typography>
                    </>
                )}
            </Box>
        )}
    </div>
);

interface ImageGalleryProps {
    images: string[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => (
    <div style={{ gap: '16px', marginBottom: 32 }}>
        {images.map((imageUrl, index) => (
            <img
                key={index}
                src={imageUrl}
                alt={`Image ${index + 1}`}
                style={{
                    width: '100%',
                    height: '300px', // Set a fixed height for equal size
                    objectFit: 'cover', // Maintain aspect ratio and cover the container
                    marginBottom: '2px',
                }}
            />
        ))}
    </div>
);



export const Section3: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box width="80%" margin="auto" >
            <Tabs value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile sx={{
                "& .MuiTabs-flexContainer": { justifyContent: { xs: 'normal', sm: "center" } },
            }}>
                <Tab label="Renewable Energy" />
                <Tab label="E-Mobility" />
                <Tab label="Agriculture" />
                <Tab label="Consulting" />
            </Tabs>

            <TabPanel value={value} index={0}>
                {[renewableEnergyImages, (
                    <>
                        {/* <ImageGallery images={renewableEnergyImages} /> */}
                        <Typography sx={styled.business} >
                        Our business in renewable energy is dedicated to harnessing the power of sustainable and environmentally friendly sources such as solar, wind, and hydropower. By developing and implementing cutting-edge energy solutions, we aim to reduce carbon emissions and promote the widespread adoption of renewable energy systems. From solar photovoltaics to wind farms, our portfolio strives to address the growing demand for clean energy while fostering a greener and more resilient planet. 
                        </Typography>
                    </>
                )]}
            </TabPanel>

            <TabPanel value={value} index={1}>
                {[eMobilityImages, (
                    <>

                        <Typography sx={styled.business}>
                        In the realm of e-mobility, our business is supporting the low-carbon transportation revolution. Presently we focus on developing and promoting electric vehicles (EVs) and the necessary infrastructure to support a widespread shift towards sustainable transportation. Through the integration of electric cars, bikes, and public transportation solutions, we aim to reduce reliance on traditional fossil fuels, decrease air pollution, and create a more sustainable and efficient mobility ecosystem. Our commitment lies in shaping the future of transportation by providing reliable, eco-friendly, and technologically advanced solutions for individuals and businesses alike.
                        </Typography>
                    </>
                )]}
            </TabPanel>

            <TabPanel value={value} index={2}>
                {[agricultureImages, (
                    <>
                        <Typography sx={styled.business}>
                        In the sphere of agriculture, our business centres on sustainable and innovative practices to enhance productivity and earning of farmers, while minimizing environmental impact. By leveraging advanced technologies and precision farming techniques, we aim to optimize resource use, improve crop yields, and promote eco-friendly agricultural practices. From agrivoltaics, smart irrigation systems to organic farming initiatives, our goal is to contribute to a more sustainable and resilient food production system. By embracing the principles of agroecology and incorporating technology-driven solutions, we strive to support both rural and urban farmers, promote biodiversity, and create a positive impact on the agricultural landscape.
                        </Typography>
                    </>
                )]}
            </TabPanel>

            <TabPanel value={value} index={3}>
                {[consultingImages, (
                    <>
                        <Typography sx={styled.business}>
                        Our consulting division is at the forefront of developing innovative sustainable energy solutions. We specialize in providing strategic guidance to our clients by providing full stack support in their decarbonisation journey. Our dedicated team of experts is committed to assisting clients in navigating the complex landscape of the low carbon transition, offering tailored solutions to optimize efficiency, reduce environmental impact, and maximize profitability. Simultaneously, our understanding of non-renewable energy sources enables us to guide clients through the challenges and opportunities presented by conventional energy options. With a forward-thinking approach and a commitment to environmental stewardship, our consulting services empower businesses to make informed decisions that align with both global economic and ecological sustainability.
                        </Typography>
                    </>
                )]}
            </TabPanel>
        </Box>

    );
};


const styled = {
    business: {
        color: '#555555', lineHeight: 1.6, fontSize: 18, marginTop: '16px', textAlign: 'center'
    }
}