import React, { ReactNode } from 'react';
import { Grid, Typography, Box, Checkbox } from '@mui/material';
import { Theme, useTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { CheckCircle } from '@mui/icons-material';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      contactUsContainer: {
        backgroundColor: 'secondary.main',
        flexDirection: 'column-reverse',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        marginBottom: '-8px'
    },
        aboutUsContainer: {
            
            padding: theme.spacing(6),
            backgroundColor: 'secondary.main',


        },
        aboutUsContent: {
            padding: theme.spacing(3),
        },
    })
);

interface CustomCheckboxProps {
    children: ReactNode;
}



const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ children }) => (
  
    <Typography variant="body1" color="text.secondary" fontFamily="Open Sans" fontWeight="bold" fontSize="18px" paragraph>
        <Checkbox icon={<CheckCircle style={{ color: useTheme().palette.primary.main }} />} checkedIcon={<CheckCircle style={{ color: useTheme().palette.primary.main }} />} color="primary" />
        {children}
    </Typography>
);

interface AnimatedSectionProps {
    delay: number;
    children: ReactNode;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ delay, children }) => {
  
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });
    

    const variants = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0 },
    };

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ duration: 1, delay }}
        >
            {children}
        </motion.div>
    );
};

export const AboutUs: React.FC = () => {
    const classes = useStyles();
    const primaryColor = useTheme().palette.primary.main;

    return (
      <Grid container className={classes.contactUsContainer}  id="about-section" >
      <Grid item xs={12} md={6} >
           
           <img src="/about.jpg" alt="About Us" style={{ width: '100%', height: '100%',}}  />
         
             </Grid>

      <Grid item xs={12} md={6} className={classes.aboutUsContainer}>
        <AnimatedSection delay={0.0}>
          <Typography variant="h3" color={primaryColor} fontWeight="bold" sx={{ pb: 1, }}>
            About Us
          </Typography>
        </AnimatedSection>

        <AnimatedSection delay={0.2}>
          <Typography variant="h4" color="f7f7f7" fontFamily="Roboto" fontWeight="700" sx={{ pb: 3 }}>
          10+ Years Experience In Renewable Energy & Transportation Industry
          </Typography>
        </AnimatedSection>

        <AnimatedSection delay={0.4}>
          <Typography variant="body1" color="text.secondary" fontFamily="Open Sans" fontSize="18px" paragraph sx={{ pb: 2, lineHeight: 2 }}>
          At Geवन, we aim to develop visionary solutions incorporating renewable energy jungles that support the ongoing global transformation and rural development. With on ground experience in green energy, transportation and agriculture industries, we aim to close the loop of carbon emission by deploying futuristic design philosophies. We envisage a world where individuals and businesses transition to carbon free age with existing synergies and maximum convenience.  
          </Typography>
        </AnimatedSection>

        <AnimatedSection delay={0.6}>
          <Typography fontWeight="bold" fontSize="18px" marginBottom="10px">
          OUR STRATEGIC TARGETS:
          </Typography>
        </AnimatedSection>

        <AnimatedSection delay={0.8}>
          <CustomCheckbox>
          To be net carbon negative by 2030
          </CustomCheckbox>
        </AnimatedSection>

        <AnimatedSection delay={1.0}>
          <CustomCheckbox >
          Empower society through localization
          </CustomCheckbox>
        </AnimatedSection>

        <AnimatedSection delay={1.0}>
          <CustomCheckbox>
          Collaborate for inclusive sustainable growth 
          </CustomCheckbox>
        </AnimatedSection>
      </Grid>
    </Grid>
    );
};
