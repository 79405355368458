import React from 'react';
import { Box, Typography,  } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  hero: { position: 'relative', height: '93vh', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', },
  heroOverlay: { position: 'absolute', width: '100%', height: '100%', background: `url(/sdg.jpg)`, backgroundSize: 'cover', filter: 'brightness(60%)', },
  heroContent: { position: 'relative', textAlign: 'center', margin: '0.625rem', color: 'white' },
}));

export const Section1 = () => {
  const classes = useStyles();

  return (
    <Box className={classes.hero}>
      <Box className={classes.heroOverlay} />
      <Box className={classes.heroContent}>
        <Typography variant="h2" sx={{marginBottom: '10px'}} >
        Join the Movement: Together, We Can Make a Difference
        </Typography>
        <Typography variant="h6"  sx={{p:3}} >
        Join us in building a more sustainable future for all. Explore our initiatives and ways to get involved.
        </Typography>
      </Box>
    </Box>

  );
};


