import React from 'react';
import Button from '@mui/material/Button';

interface CustomButtonProps {
  onClick?: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ onClick }) => {
  return (
    <Button variant="outlined" color="secondary" sx={{ fontSize: 14, textTransform: 'none' }} onClick={onClick} size='small'>
      Explore
    </Button>
  );
};

export { CustomButton };
