import { Typography, Box } from '@mui/material';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroSection: {
      position: 'relative',
      height: 'calc(100vh - 56px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    video: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: -1,
      filter: 'brightness(80%)'
    },
    content: {
      color: theme.palette.common.white,
      zIndex: 1,
      display: 'flex', flexDirection: 'column', padding: '10%'
    },
  })
) as () => Record<string, string>;

export const HeroSection: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.heroSection}>
      <video autoPlay loop muted className={classes.video}>
        <source src='/video.mp4' type="video/mp4" />
      </video>
      <Box className={classes.content}>
      <Typography variant="h2" gutterBottom style={{ fontSize: '60px', fontFamily: 'IBM Plex Sans', fontWeight: 'bold' }}>
        The Future, For Present 
        </Typography>
        <Typography variant="body1" gutterBottom style={{fontSize: '30px', fontFamily: 'IBM Plex Sans', fontWeight: 'semi-bold' }}>
        Embrace the future of net zero carbon with green energy and emission free transportation. Join us in embracing a greener, cleaner and more sustainable world.
        </Typography>
      </Box>
    </Box>
  );
};
