import React from 'react';
import './App.css';
import { HomePage } from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/navbar';

import CssBaseline from '@mui/material/CssBaseline';
import { BusinessesPage } from './pages/Business';
import { SustainabilityPage } from './pages/Sustainability';
import { LoginForm } from './pages/Login';
import { Footer } from './components/footer';
import { ContactForms } from './pages/ContactForms';
import { useAuth } from './context';
import { Page404 } from './pages/404';



function App() {
  return (
    <Router>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/businesses" element={<BusinessesPage />} />
          <Route path="/sustainability" element={<SustainabilityPage />} />
          {/* <Route path="/login" element={<LoginForm />} /> */}
          <Route path="/admin" element={<ConditionalContactForms />} />
          <Route path="*" element={<Page404 />} />

        </Routes>
        <Footer />
      </Router>
  );
}

const ConditionalContactForms = () => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <ContactForms /> : <LoginForm />;
};

export default App;
