import React from 'react';
import { Section1 } from './section1';
import { Section2 } from './section2';
import { Section3 } from './section3';
import { Box } from '@mui/material';


export const BusinessesPage = () => {
  return (
    <Box bgcolor="#f6f7f8" >
      <Section1 />
      <Section2 />
      <Section3 />

    </Box>
    
  );
};