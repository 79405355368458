// AuthContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, signInWithEmailAndPassword, updatePassword } from '../firebase.config';
import { signOut } from 'firebase/auth';

interface AuthContextType {
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  changePassword: (newPassword: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const login = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      console.error('Login Error:', error.message);
      throw error;
    }
  };

  const logout = () => {
    signOut(auth).then(() => {
      sessionStorage.removeItem("accessToken");
      window.location.reload();
    })
  };

  const changePassword = async (newPassword: string) => {
    try {
      const user = auth.currentUser;
      if (user) {
        await updatePassword(user, newPassword);
      } else {
        console.log('User Not Found');
      }
    } catch (error: any) {
      console.error('Change Password Error:', error.message);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user) {
        user.getIdToken().then((token: string) => {
          sessionStorage.setItem("accessToken", token);
          setIsLoggedIn(true);
        });
      } else {
        sessionStorage.removeItem("accessToken");
      }
    });

    return unsubscribe;
  }, []);

  const contextValue: AuthContextType = {
    isLoggedIn,
    login,
    logout,
    changePassword,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
