
import React from 'react';
import { HeroSection } from '../../components/herosection';
import { FeatureSection } from '../../components/services';
import { Box } from '@mui/material';
import { AboutUs } from '../../components/aboutUs';
import { Intro } from '../../components/intro';
import { ContactUs } from '../../components/contactus';
import { Business } from '../../components/business';
import { Sustainability } from '../../components/sustainability';

export const HomePage = () => {
  return (
    <Box>
      <HeroSection />
      <AboutUs  />
      <Business />
      <FeatureSection  />
      <Sustainability />
      <Intro />
      <ContactUs />
    </Box>
    
  );
};
